
































































































































































































































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { UserListObj, UserAddParams, Department } from './types'
import { Form } from 'element-ui'
import { formatTimestamp } from '@/utils/utils'

@Component({ name: 'DepartmentList' })
export default class DepartmentList extends VueBase {
  private page = 1
  private pageSize = 20
  private total = 0
  private keywords = ''
  private newDepartment = false
  private currentDepartmentName = ''
  private department: Department = {
    id: -1,
    name: '',
  }
  private isAdd = true
  private tableData: Array<UserListObj> = []
  private departments: Array<Department> = []
  private addDialogOpen = false
  private userForm: UserAddParams = {
    username: '',
    password: '',
    re_password: '',
    email: '',
    role: 0,
    department: {
      id: -1,
      name: '',
    },
    phone: '',
    uid: 0,
  }
  private rules = {
    username: [
      {
        required: true,
        message: this.$t('views.userList.namePlaceholder'),
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        message: this.$t('views.userList.emailPlaceholder'),
        trigger: 'blur',
      },
    ],
    phone: [
      {
        required: true,
        message: this.$t('views.userList.phonePlaceholder'),
        trigger: 'blur',
      },
    ],
    password: [{ validator: this.validateNewPass, trigger: 'blur' }],
    re_password: [{ validator: this.validateCheckPass, trigger: 'blur' }],
  }
  private data = []
  private formatTime(time: string) {
    if (!time) {
      return this.$t('views.userList.notLogin') as string
    }
    return formatTimestamp(new Date(time).getTime())
  }
  private validateNewPass(rule: any, value: string, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('views.userList.needPWD') as string))
    } else {
      if (this.userForm.password !== '') {
        ;(this.$refs.ruleForm as Form).validateField('re_password')
      }
      callback()
    }
  }
  private validateCheckPass(rule: any, value: any, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('views.userList.rePWD') as string))
    } else if (value !== this.userForm.password) {
      callback(new Error(this.$t('views.userList.diffPWD') as string))
    } else {
      callback()
    }
  }

  created() {
    this.departmentList()
    this.getTableData()
  }

  private addDialogShow() {
    this.userForm = {
      username: '',
      password: '',
      re_password: '',
      email: '',
      role: 0,
      department: this.department,
      phone: '',
      uid: 0,
    }
    this.addDialogOpen = true
  }

  private userEdit(row: UserListObj) {
    this.isAdd = false
    this.userForm = {
      username: row.username,
      password: '',
      re_password: '',
      email: row.email,
      role: row.is_superuser,
      department: row.department,
      phone: row.phone,
      uid: row.id,
    }
    this.addDialogOpen = true
  }

  private cancelAdd() {
    this.addDialogOpen = false
    if (this.isAdd === false) {
      this.isAdd = true
    }
  }

  private async resetPwd(item: any) {
    this.$confirm(
      this.$t('views.userList.deleteConfirm') as string,
      this.$t('views.userList.deleteConfirmPop') as string,
      {
        confirmButtonText: this.$t('views.userList.submit') as string,
        cancelButtonText: this.$t('views.userList.cancel') as string,
        type: 'warning',
      }
    ).then(async () => {
      const { status, msg } = await this.services.user.reset({
        userId: item.id as number,
      })
      if (status === 201) {
        this.$message({
          type: 'success',
          message: msg,
          showClose: true,
        })
      } else {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
      }
    })
  }

  private newSelectData() {
    this.page = 1
    this.getTableData()
  }
  private currentChange(val: number) {
    this.page = val
    this.getTableData()
  }
  private async getTableData() {
    const params: {
      page: number
      pageSize: number
      departmentId?: number
      keywords?: string
    } = {
      page: this.page,
      pageSize: this.pageSize,
      departmentId: this.department.id,
    }
    if (this.keywords) {
      params.keywords = this.keywords
    }
    this.loadingStart()
    const { status, msg, data, total } = await this.services.user.userList(
      params
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.tableData = data
    this.total = total
  }

  private async departmentList() {
    this.loadingStart()
    const { status, msg, data } =
      await this.services.department.departmentList()
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.data = data
  }

  private userAdd() {
    ;(this.$refs.ruleForm as Form).validate(async (valid: any) => {
      if (valid) {
        const params: UserAddParams = {
          username: this.userForm.username,
          password: this.userForm.password,
          re_password: this.userForm.re_password,
          email: this.userForm.email,
          role: this.userForm.role,
          department: this.userForm.department,
          phone: this.userForm.phone,
        }
        if (this.userForm.uid) {
          params.uid = this.userForm.uid
        }
        this.loadingStart()
        if (this.isAdd) {
          const { status, msg } = await this.services.user.userAdd(params)
          this.loadingDone()
          if (status !== 201) {
            this.$message({
              type: 'error',
              message: msg,
              showClose: true,
            })
            return
          }
        } else {
          const { status, msg } = await this.services.user.userEdit(params)
          this.loadingDone()
          if (status !== 201) {
            this.$message({
              type: 'error',
              message: msg,
              showClose: true,
            })
            return
          }
        }
        this.addDialogOpen = false
        this.getTableData()
        this.isAdd = true
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }

  private async userDelete(uid: number) {
    this.loadingStart()
    const { status, msg } = await this.services.user.userDelete({
      uid,
    })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    await this.getTableData()
  }

  private append(node: any, data: any) {
    this.newDepartment = true
    const defaultDepartmentName = this.$t(
      'views.strategyManage.children'
    ) as string
    const newChild = {
      id: data.id + 1,
      label: this.$t('views.strategyManage.children') as string,
      isEdit: 1,
      children: [],
    }
    if (!data.children) {
      this.$set(data, 'children', [])
    }
    this.currentDepartmentName = defaultDepartmentName
    data.children.push(newChild)
  }

  private async remove(node: any, data: any) {
    const { status, msg } = await this.services.department.departmentDel(
      data.id,
      data
    )

    if (status === 201) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex((d: any) => d.id === data.id)
      children.splice(index, 1)
      this.$message({
        type: 'success',
        message: msg,
        showClose: true,
      })
    } else {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
    }
  }

  private SelectNode(data: any, node: any, obj: any) {
    node.expanded = true
    if (
      data.isEdit === undefined ||
      (data.isEdit === 0 && this.newDepartment == false && data.id !== -1)
    ) {
      this.department.id = data.id
      this.department.name = data.label
      this.getTableData()
    }
  }

  edit(node: any, data: { id: number; label: string; isEdit: boolean }) {
    this.newDepartment = false
    this.$set(data, 'isEdit', 1)
    this.currentDepartmentName = data.label
  }

  private async submitEdit(node: any, nodeData: any) {
    if (nodeData.label == this.currentDepartmentName) {
      this.currentDepartmentName = ''
      this.$set(nodeData, 'isEdit', 0)

      if (this.newDepartment) {
        const parent = node.parent
        const children = parent.data.children || parent.data
        const index = children.findIndex((d: any) => d.id === nodeData.id)
        children.splice(index, 1)
        this.newDepartment = false
      }
    } else {
      const params = {
        name: this.currentDepartmentName,
        parent: node.parent.data.id,
      }
      if (this.newDepartment) {
        // 创建部门
        const { status, msg, data } =
          await this.services.department.departmentAdd(params)
        if (status !== 201) {
          this.$set(data, 'isEdit', 0)
          this.$message({
            type: 'error',
            message: msg,
            showClose: true,
          })
          return
        } else {
          this.$set(nodeData, 'id', data)
          this.$set(nodeData, 'label', this.currentDepartmentName)
          this.currentDepartmentName = ''
          this.$set(nodeData, 'isEdit', 0)
          this.$message({
            type: 'success',
            message: msg,
            showClose: true,
          })
        }
        this.newDepartment = false
      } else {
        const { status, msg } = await this.services.department.departmentUpdate(
          nodeData.id,
          params
        )
        if (status !== 201) {
          this.$set(nodeData, 'isEdit', 0)
          this.$message({
            type: 'error',
            message: msg,
            showClose: true,
          })
          return
        } else {
          this.$set(nodeData, 'label', this.currentDepartmentName)
          this.currentDepartmentName = ''
          this.$set(nodeData, 'isEdit', 0)
          this.$message({
            type: 'success',
            message: msg,
            showClose: true,
          })
        }
      }
    }
  }
}
